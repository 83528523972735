import React from "react";
import PropTypes from "prop-types";

function uuid() {
  var chars = "0123456789abcdef".split("");

  var uuid = [],
    rnd = Math.random,
    r;
  uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
  uuid[14] = "4"; // version 4

  for (var i = 0; i < 36; i++) {
    if (!uuid[i]) {
      r = 0 | (rnd() * 16);

      uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r & 0xf];
    }
  }

  return uuid.join("");
}

class StarRating extends React.Component {
  render() {
    const { stars, color, size } = this.props;
    const buildStars = () => {
      let starItems = [];
      for (let i = 0; i < Math.floor(stars); i++) {
        starItems.push(
          <i
            key={uuid()}
            className="material-icons"
            style={{ color: color, fontSize: size }}
          >
            star
          </i>
        );
      }
      if (stars - Math.floor(stars) > 0) {
        starItems.push(
          <i
            key={uuid()}
            className="material-icons"
            style={{ color: color, fontSize: size }}
          >
            star_half
          </i>
        );
      }
      for (let n = 0; n < 5 - Math.ceil(stars); n++) {
        starItems.push(
          <i
            key={uuid()}
            className="material-icons"
            style={{ color: color, fontSize: size }}
          >
            star_border
          </i>
        );
      }
      return starItems;
    };
    return <>{buildStars(stars)}</>;
  }
}

StarRating.propTypes = {
  stars: PropTypes.number,
};

StarRating.defaultProps = {
  stars: 3,
};

export default StarRating;

import React from "react";
import { graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import * as JsSearch from "js-search";

import Layout from "../components/layout";
import ProductPreviewCard from "../components/product-preview-card";
import LoadingImage from "../../static/images/loading.svg";

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      isLoading: true,
      search: [],
      searchResults: [],
      isError: false,
      searchQuery: "",
    };
    this.inputNode = React.createRef();
  }

  async componentDidMount() {
    try {
      const res = await axios.get("/products.json");
      this.setState({ products: res.data });
      this.rebuildIndex();
      const query = this.searchQuery;
      if (!query) {
        return this.setState({ searchQuery: query, searchResults: [] });
      }
      const queryResult = this.state.search.search(query);
      this.setState({ searchQuery: query, searchResults: queryResult });
    } catch (err) {
      this.setState({ isError: true });
      console.error(err);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      if (this.state.searchQuery !== this.searchQuery) {
        this.setState({ isLoading: true });
        const queryResult = this.state.search.search(this.searchQuery);
        this.setState({
          searchQuery: this.searchQuery,
          searchResults: queryResult,
          isLoading: false,
        });
      }
    }
  }

  rebuildIndex() {
    const { products } = this.state;
    const dataToSearch = new JsSearch.Search("title");
    dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("title");
    dataToSearch.addIndex("title");
    dataToSearch.addDocuments(products);
    this.setState({ search: dataToSearch, isLoading: false });
  }

  getSearchQuery(params) {
    return new URLSearchParams(params).get("q");
  }

  get searchQuery() {
    return this.URLParams.get("q");
  }

  get URLParams() {
    return new URLSearchParams(this.props.location.search);
  }

  render() {
    const { searchResults } = this.state;
    // Filter products that matches search.
    var products = this.props.data.products.edges
      .map((edge) => edge.node.frontmatter)
      .filter((product) =>
        searchResults.some((res) => res.slug === product.slug),
      );

    return (
      <Layout>
        <Container>
          <section id="welcome" className={`mt-5`}>
            <h1>Results</h1>
          </section>
          <hr xs="12" className={`mt-1`} />

          <section id="products" className={`mt-5`}>
            {/* <div className={`flex-column d-flex`}> */}
            <Row className="justify-content-center">
              <Col style={{ maxWidth: 800 }} hidden={!this.state.isLoading}>
                <div
                  style={{
                    marginTop: 40,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    width="40"
                    height="40"
                    style={{ textAlign: "center" }}
                    src={`${LoadingImage}`}
                    alt="loading"
                  />
                </div>
              </Col>
              {products.length ? (
                products.map((product, index) => (
                  <Col
                    key={index}
                    className="mb-5 col-12"
                    hidden={this.state.isLoading}
                    style={{ maxWidth: 800 }}
                  >
                    <ProductPreviewCard
                      title={product.title}
                      slug={product.slug}
                      url={product.offer.url}
                      logo={product.logo}
                      description={product.showcase.description}
                      stars={product.stars}
                    />
                  </Col>
                ))
              ) : this.state.isError ? (
                <p style={{ padding: 20 }}>
                  An error occured while fetching the search results, please try
                  again.
                </p>
              ) : (
                <p style={{ padding: 20 }} hidden={this.state.isLoading}>
                  No products found.
                </p>
              )}
              {/* </div> */}
            </Row>
          </section>
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    products: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "product" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            stars
            offer {
              url
            }
            logo {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            showcase {
              description
            }
          }
        }
      }
    }
  }
`;

export default Search;

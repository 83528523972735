import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Img from "gatsby-image";
import injectSheet from "react-jss";
import { Row, Col, Card, Button } from "react-bootstrap";

import StarRating from "../components/star-rating";

const styles = {
  root: {
    width: "100%",
    maxWidth: 800,
    "& .card-title": {
      fontSize: "1.85rem",
    },
    "& a:not(.btn)": {
      color: "#000",
      "&:hover": {
        color: "#000",
        textDecoration: "none",
      },
    },
    "& img": {
      padding: 20,
      border: "1px solid rgba(0,0,0,.125)",
    },
    "& .gatsby-image-wrapper": {
      boxShadow: "0 0 0.25em #dae1e4",
    },
    "& img:hover": {
      borderColor: "#B2CCDB",
    },
  },
};

class ProductPreviewCard extends React.Component {
  render() {
    const { classes, title, slug, url, logo, description, stars } = this.props;
    return (
      <Card className={classes.root}>
        <Row noGutters="true">
          <Col md="4" className={`p-4`}>
            <Link to={`/${slug}`}>
              <Img
                loading="eager"
                fluid={logo.childImageSharp.fluid}
                placeholderStyle={{ visibility: "hidden" }}
              />
            </Link>
          </Col>
          <Col md="8">
            <Card.Body className={`ml-0 ml-md-3`}>
              <Row noGutters="true">
                <Col md="6">
                  <Card.Title className="mt-1">{title}</Card.Title>
                </Col>
                <Col md="6">
                  <Button size="lg" block href={url}>
                    Visit Website
                  </Button>
                </Col>
              </Row>
              <div className={`star-rating mt-3`}>
                <StarRating stars={Number(stars)} />
              </div>
              <Card.Text className={`mt-3`}>{description}</Card.Text>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    );
  }
}

const StyledProductPreviewCard = injectSheet(styles)(ProductPreviewCard);

ProductPreviewCard.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  logo: PropTypes.object.isRequired,
  stars: PropTypes.number.isRequired,
};

export default StyledProductPreviewCard;
